<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <div class="w-100 i-margin-div-w" />
        <div class="i-progress-container mt-1 mb-3">
          <div
            id="i-progress"
            class="i-progress"
          />
          <!-- tab 1 -->
          <div class="i-circle-progress active">
            <span style="transform: translate(0, -30px);">
              <!-- version desktop -->
              <p
                class="text-center mt-1 d-none d-sm-none d-md-block"
                style="white-space:nowrap;"
              >
                <i
                  class="icon-0-icons-dark-football text-center"
                  style="font-size: 21px;"
                />
                <span class="align-bottom">{{ $t('corporate.club') }}</span>
              </p>
              <!--/ version desktop -->
              <!-- version mobile -->
              <div class="text-center mt-3 pt-2 d-block d-sm-block d-md-none">
                <p>
                  <i
                    class="icon-0-icons-dark-football text-center"
                    style="font-size: 21px;"
                  />
                </p><br>
                <p v-if="currentActive === 1">
                  <span>{{ $t('corporate.club') }}</span>
                </p>
                <p
                  v-else
                  style="margin-top: 33px;"
                />
              </div>
              <!--/ version mobile -->
            </span>
          </div>
          <!--/ tab 1 -->
          <!-- tab 2 -->
          <div class="i-circle-progress">
            <span style="transform: translate(0, -30px);">
              <!-- version desktop -->
              <p
                class="text-center mt-1 d-none d-sm-none d-md-block"
                style="white-space:nowrap;"
              >
                <i
                  class="icon-0-icons-dark-contacts text-center"
                  style="font-size: 21px; margin-right: 2px"
                />
                <span class="align-bottom">{{ $t('corporate.society') }}</span>
              </p>
              <!--/ version desktop -->
              <!-- version mobile -->
              <div class="text-center mt-3 pt-2 d-block d-sm-block d-md-none">
                <p>
                  <i
                    class="icon-0-icons-dark-contacts text-center"
                    style="font-size: 21px; margin-right: 2px"
                  />
                </p><br>
                <p v-if="currentActive === 2">
                  <span>{{ $t('corporate.society') }}</span>
                </p>
                <p
                  v-else
                  style="margin-top: 33px;"
                />
              </div>
              <!--/ version mobile -->
            </span>
          </div>
          <!--/ tab 2 -->
          <!-- tab 3 -->
          <div class="i-circle-progress">
            <span style="transform: translate(0, -30px);">
              <!-- version desktop -->
              <p
                class="text-center mt-1 d-none d-sm-none d-md-block"
                style="white-space:nowrap;"
              >
                <feather-icon
                  icon="EditIcon"
                  size="20"
                  class="mr-50"
                />
                <span class="align-bottom">{{ $t('routes.documentalCollectionRoute.representatives') }}</span>
              </p>
              <!--/ version desktop -->
              <!-- version mobile -->
              <div class="text-center mt-3 pt-2 d-block d-sm-block d-md-none">
                <p>
                  <feather-icon
                    icon="EditIcon"
                    size="20"
                    class="mr-50"
                  />
                </p><br>
                <p v-if="currentActive === 3">
                  <span>{{ $t('routes.documentalCollectionRoute.representatives') }}</span>
                </p>
                <p
                  v-else
                  style="margin-top: 33px;"
                />
              </div>
              <!--/ version mobile -->
            </span>
          </div>
          <!--/ tab 3 -->
        </div>
      </b-col>
    </b-row>
    <b-row>
      <div
        v-if="currentActive == 1"
        class="w-100"
      >
        <create-club
          :old-active="oldActive"
          :params-club="paramsClub"
          :step-number="stepNumber"
          :step-previous-number="stepPreviousNumber"
          @save-data-step-one="getNextView"
          @save-data-prev-one="getPreviousView"
        />
        <!-- @assign-color-in-btn="" -->
      </div>
      <b-col
        v-if="currentActive == 2"
        cols="12"
      >
        <create-society
          :actions="{ clubId: null, societyId: null, name: '' }"
          :old-active="oldActive"
          :params-society="paramsSociety"
          :step-number="stepNumber"
          :step-previous-number="stepPreviousNumber"
          @save-data-step-two="getNextView"
          @save-data-prev-two="getPreviousView"
        />
      </b-col>
      <b-col
        v-if="currentActive == 3"
        cols="12"
      >
        <create-representative
          :actions="{ clubId: null, representativeId: null, name: '' }"
          :old-active="oldActive"
          :params-representative="paramsRepresentative"
          :step-number="stepNumber"
          :step-previous-number="stepPreviousNumber"
          :params-society="paramsSociety"
          @save-data-step-three="getNextView"
          @save-data-prev-three="getPreviousView"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <hr>
        <b-button
          id="next"
          variant="primary"
          pill
          class="ml-1 float-right"
          :style="colorPrimaryBtn"
          @click="stepNumber = currentActive"
        >
          {{ currentActive == 3 ? $t('buttons.finalize') : $t('buttons.next') }}
        </b-button>
        <b-button
          id="previous"
          variant="secondary"
          pill
          class="float-right"
          @click="stepPreviousNumber = (currentActive - 1)"
        >
          {{ currentActive == 1 ? $t('buttons.cancel') : $t('buttons.previous') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { mapActions, mapGetters } from 'vuex'
import { BCard, BButton } from 'bootstrap-vue'
import CreateClub from './Create.vue'
import CreateSociety from './societies/Create.vue'
import CreateRepresentative from './representatives/Create.vue'

export default {
  components: {
    CreateClub,
    CreateSociety,
    CreateRepresentative,
    BCard,
    BButton,
  },

  computed: {
    ...mapGetters({ colorPrimaryBtn: 'colorPrimaryBtn' }),
  },

  methods: {
    ...mapActions(['saveClubDataForm']),
  },

  setup() {
    const progress = ref(null)
    const previous = ref(null)
    const next = ref(null)
    const circles = ref(null)
    const oldActive = ref(null)
    const currentActive = ref(null)
    const stepNumber = ref(null)
    const stepPreviousNumber = ref(null)
    const paramsClub = ref(null)
    const paramsSociety = ref(null)
    const paramsRepresentative = ref(null)
    const assignPrevious = ref(null)

    watch(currentActive, (currentValue, oldValue) => {
      oldActive.value = oldValue
    })

    onMounted(() => {
      progress.value = document.getElementById('i-progress')
      previous.value = document.getElementById('previous')
      next.value = document.getElementById('next')
      circles.value = document.querySelectorAll('.i-circle-progress')
      currentActive.value = 1

      if (circles.value.length == 1) {
        progress.value.style.width = '100%'
      } else if (circles.value.length == 2) {
        progress.value.style.width = '50%'
      } else if (circles.value.length >= 3) {
        progress.value.style.width = `${(1) / (circles.value.length) * 100}%`
      }
    })

    /* 'no modificar', solo actualiza el estado actual del step 'diseño (estilos) y sección activa', no los datos */
    const update = () => {
      circles.value.forEach((circle, idx) => {
        if (idx < currentActive.value) {
          circle.classList.add('active')
        } else {
          circle.classList.remove('active')
        }
      })

      const actives = ref(document.querySelectorAll('.active'))

      if (circles.value.length == 1) {
        progress.value.style.width = '100%'
      } else if (circles.value.length == 2) {
        progress.value.style.width = `${(actives.value.length - 1) / 2 * 100}%`
      } else if (circles.value.length >= 3) {
        progress.value.style.width = `${(actives.value.length - 1) / (circles.value.length) * 100}%`
      }

      if (currentActive.value === 1) {
        // previous.value.disabled = true
        next.value.disabled = false
      } else if (currentActive.value === circles.value.length) {
        next.value.disabled = false
        previous.value.disabled = false
      } else {
        previous.value.disabled = false
        next.value.disabled = false
      }
    }
    /* 'no modificar', solo actualiza el estado actual del step 'diseño (estilos) y sección activa', no los datos */

    const showErrorInFields = () => {
      this.$swal({
        title: '¡Error!',
        text: 'Verifica que tus datos sean correctos',
        icon: 'error',
        timer: 2000,
        showConfirmButton: true,
        confirmButtonText: `${this.$t('generic.accept')}`,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    }

    return {
      stepNumber,
      stepPreviousNumber,
      oldActive,
      currentActive,
      paramsClub,
      paramsSociety,
      paramsRepresentative,

      getPreviousView(dForm) {
        stepNumber.value = null
        assignPrevious.value = false

        if (currentActive.value == 1) {
          this.$router.push({ name: 'clubs' })
        } else if (currentActive.value == 2) {
          paramsSociety.value = dForm // respaldando lo agregado en paso 2
          assignPrevious.value = true
        } else if (currentActive.value == 3) {
          paramsRepresentative.value = dForm // respaldando lo agregado en paso 3
          console.log('lo que llego como representantes', dForm)
          assignPrevious.value = true
        }

        if (assignPrevious.value) {
          currentActive.value -= 1

          if (currentActive.value < 1) {
            currentActive.value = 1
          }

          update()
        }
      },

      getNextView(pData, pFlag, pHasSoc) {
        stepPreviousNumber.value = null

        const assignCurrent = ref(false)

        if (currentActive.value == 1) {
          if (pFlag) {
            console.log('mis datos', pData)

            paramsClub.value = pData
            assignCurrent.value = pFlag
          }
        } else if (currentActive.value == 2) {
          if (pFlag) {
            console.log('mis datos 2', pData)

            paramsSociety.value = pData
            assignCurrent.value = pFlag
          }
        } else if (currentActive.value == 3) {
          console.log('mis datos 3', pData)

          paramsRepresentative.value = pData
          assignCurrent.value = pFlag

          const objectDataClub = {
            club: paramsClub.value,
            society: paramsSociety.value,
            representative: paramsRepresentative.value,
            hasSocieties: pHasSoc,
          }

          this.saveClubDataForm(objectDataClub)
          // this.fetchClubById(paramsClub.value.id)

          setTimeout(() => {
            this.$router.push({ name: 'clubs' })
          }, 2000)
        }

        if (assignCurrent.value) {
          currentActive.value += 1

          if (currentActive.value > circles.value.length) {
            currentActive.value = circles.value.length
          }

          update()
        } else {
          stepNumber.value = null
          showErrorInFields()
        }
      },
    }
  },
}
</script>
