var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',[_c('strong',[_vm._v(_vm._s(_vm.$t('clubs.representatives.representativeRegistration')))])]),_c('span',{staticClass:"i-text-request-info"},[_vm._v(" "+_vm._s(_vm.$t('corporate.create.textRequest'))+" ")]),_c('hr')]),_c('b-col',{attrs:{"md":"12"}},[_c('b-overlay',{class:_vm.applyOverlay ? 'p-2 mb-1' : 'mb-1',attrs:{"show":_vm.applyOverlay,"opacity":"1"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-center mt-1"},[_c('b-spinner',{attrs:{"type":"border","variant":"primary"}})],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('p',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.$t('loading'))+" ")])])]},proxy:true}])},[_c('b-row',[(!_vm.applyOverlay)?_c('b-col',{attrs:{"md":"12"}},[_c('validation-observer',{ref:"representativesRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.representativesValidation()}}},[_c('b-row',[(!_vm.showInstrumentField)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"representative-other-power"}},[_vm._v(" "+_vm._s(_vm.$t('clubs.instrumentNumber.labels.instrumentNumbers'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"instrumentNumbers","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"representative-other-power","state":errors.length > 0 ? false : null,"multiple":"","label":"type","reduce":function (name) { return name.id; },"options":_vm.instruments,"placeholder":_vm.$t('clubs.representatives.placeholder.selectInstrumentNumbers')},model:{value:(_vm.form.instrumentNumbers),callback:function ($$v) {_vm.$set(_vm.form, "instrumentNumbers", $$v)},expression:"form.instrumentNumbers"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,89766320)})],1)],1):_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"representative-other-power"}},[_vm._v(" "+_vm._s(_vm.$t('clubs.instrumentNumber.labels.instrumentNumber'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"instrumentNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"society-duration","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('clubs.representatives.placeholder.selectInstrumentNumber'),"disabled":""},model:{value:(_vm.form.instrumentNumber),callback:function ($$v) {_vm.$set(_vm.form, "instrumentNumber", $$v)},expression:"form.instrumentNumber"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,23355577)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"i-font-size-date",attrs:{"label-for":"representative-undefined"}},[_vm._v(" "+_vm._s(_vm.$t('clubs.representatives.admitMoreThanOneRepresentative'))+" "),_c('br'),_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":""},model:{value:(_vm.form.hasManyRepresentatives),callback:function ($$v) {_vm.$set(_vm.form, "hasManyRepresentatives", $$v)},expression:"form.hasManyRepresentatives"}},[_c('span',[_vm._v(" "+_vm._s(_vm.form.hasManyRepresentatives ? _vm.$t('generic.yes') : _vm.$t('generic.not'))+" ")])])],1)])],1),_c('b-row',[(_vm.form.hasManyRepresentatives)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"representatives-name"}},[_vm._v(" "+_vm._s(_vm.$t('clubs.representatives.representatives'))+" "),(!_vm.applyOtherRep && _vm.userList.length)?_c('span',{staticClass:"text-danger"},[_vm._v(" * ")]):_vm._e(),_c('validation-provider',{attrs:{"name":"representatives","rules":_vm.applyOtherRep || _vm.userList.length == 0 ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"representatives-name","state":errors.length > 0 ? false : null,"multiple":"","label":"name","reduce":function (name) { return name.hash; },"options":_vm.userList,"placeholder":_vm.$t('clubs.representatives.placeholder.selectRepresentatives'),"disabled":_vm.userList.length == 0},model:{value:(_vm.form.representatives),callback:function ($$v) {_vm.$set(_vm.form, "representatives", $$v)},expression:"form.representatives"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2903894992)})],1)],1):_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"representative-name"}},[_vm._v(" "+_vm._s(_vm.$t('clubs.representatives.representative'))+" "),(!_vm.applyOtherRep && _vm.userList.length)?_c('span',{staticClass:"text-danger"},[_vm._v(" * ")]):_vm._e(),_c('validation-provider',{attrs:{"name":"representativeIn","rules":_vm.applyOtherRep || _vm.userList.length == 0 ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"representative-name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"state":errors.length > 0 ? false : null,"label":"name","reduce":function (name) { return name.hash; },"options":_vm.userList,"placeholder":_vm.$t('clubs.representatives.placeholder.selectRepresentative'),"disabled":_vm.applyOtherRep || _vm.userList.length == 0},model:{value:(_vm.form.representativeId),callback:function ($$v) {_vm.$set(_vm.form, "representativeId", $$v)},expression:"form.representativeId"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,83729610)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-button',{staticClass:"mt-2",attrs:{"variant":_vm.applyOtherRep ? 'secondary' : 'primary',"pill":"","disabled":_vm.userList.length == 0},on:{"click":function($event){_vm.applyOtherRep = !_vm.applyOtherRep}}},[(_vm.applyOtherRep)?_c('span',[_vm._v(_vm._s(_vm.$t('buttons.cancel')))]):_c('span',[_vm._v(_vm._s(_vm.form.hasManyRepresentatives ? _vm.$t('clubs.representatives.addNewRepresentatives') : _vm.$t('clubs.representatives.addNewRepresentative')))])])],1),(_vm.applyOtherRep)?_c('b-col',{attrs:{"md":"6"}},[(!_vm.form.hasManyRepresentatives)?_c('b-form-group',{attrs:{"label-for":"representative-other-option"}},[_vm._v(" "+_vm._s(_vm.$t('clubs.representatives.newRepresentative'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"newRepresentative","rules":"required|nameRegex|min:1|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"representative-other-option","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('clubs.representatives.placeholder.enterNewRepresentative')},model:{value:(_vm.form.otherRepresentative),callback:function ($$v) {_vm.$set(_vm.form, "otherRepresentative", $$v)},expression:"form.otherRepresentative"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,177461726)})],1):_c('b-form-group',{attrs:{"label-for":"new-representatives-other-option"}},[_vm._v(" "+_vm._s(_vm.$t('clubs.representatives.newRepresentatives'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"newRepresentatives","rules":"required|min:1|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"new-representatives-other-option","state":errors.length > 0 ? false:null,"multiple":"","taggable":"","push-tags":"","placeholder":_vm.$t('clubs.representatives.placeholder.enterNewRepresentatives'),"label":"title"},model:{value:(_vm.form.otherRepresentatives),callback:function ($$v) {_vm.$set(_vm.form, "otherRepresentatives", $$v)},expression:"form.otherRepresentatives"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,550117589)})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"representative-powers-granted"}},[_vm._v(" "+_vm._s(_vm.$t('clubs.representatives.powersGranted'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"powersGranted","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"representative-powers-granted","state":errors.length > 0 ? false : null,"multiple":"","label":"name","reduce":function (name) { return name.id; },"options":_vm.powers,"placeholder":_vm.$t('clubs.representatives.placeholder.selectPowersGranted')},model:{value:(_vm.form.powersGranted),callback:function ($$v) {_vm.$set(_vm.form, "powersGranted", $$v)},expression:"form.powersGranted"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3988271645)})],1)],1),_c('b-col',{attrs:{"sm":"6","md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"for":"society-incorporation-date"}},[_vm._v(" "+_vm._s(_vm.$t('clubs.representatives.validityPeriod'))+" "),(!_vm.form.undefinedDate)?_c('span',{staticClass:"text-danger"},[_vm._v(" * ")]):_vm._e(),_c('validation-provider',{attrs:{"name":"validityPeriod","rules":_vm.form.undefinedDate ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"representative-date-range","state":errors.length > 0 ? false:null,"config":_vm.config,"disabled":_vm.form.undefinedDate,"placeholder":_vm.$t('clubs.representatives.placeholder.selectADateRange')},model:{value:(_vm.form.dateRange),callback:function ($$v) {_vm.$set(_vm.form, "dateRange", $$v)},expression:"form.dateRange"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2916884735)})],1)],1),_c('b-col',{attrs:{"sm":"6","md":"4","lg":"3"}},[_c('label',{staticClass:"i-font-size-date",attrs:{"label-for":"representative-undefined"}},[_vm._v(" "+_vm._s(_vm.$t('generic.undefinedDate'))+" "),_c('br'),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":""},model:{value:(_vm.form.undefinedDate),callback:function ($$v) {_vm.$set(_vm.form, "undefinedDate", $$v)},expression:"form.undefinedDate"}},[_c('span',[_vm._v(_vm._s(_vm.form.undefinedDate ? _vm.$t('generic.yes') : _vm.$t('generic.not')))])])],1)],1)],1)]),(_vm.applyTextArea)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"representative-special"}},[_vm._v(" "+_vm._s(_vm.$t('clubs.representatives.specialPowers'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"specialPowers","rules":"required|clubNameRegex|min:1|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"representative-special","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('clubs.representatives.placeholder.enterSpecialPowers'),"rows":"3"},model:{value:(_vm.form.specialPowersGranted),callback:function ($$v) {_vm.$set(_vm.form, "specialPowersGranted", $$v)},expression:"form.specialPowersGranted"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1615055039)})],1)],1):_vm._e(),(_vm.checkPermission(['ALLOW_ALL']))?_c('b-col',{attrs:{"md":"12"}},[_c('hr'),_c('b-button',{staticClass:"float-right ml-1",style:(_vm.colorPrimaryBtn),attrs:{"variant":"primary","pill":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t( 'buttons.save'))+" ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"secondary","pill":""},on:{"click":function($event){return _vm.$emit('change-type-view')}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")])],1):_vm._e()],1)],1)],1)],1):_c('b-col',{staticClass:"i-height-div",attrs:{"cols":"12"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }