<template>
  <b-card>
    <b-row v-if="checkPermission(['ALLOW_ALL'])">
      <b-col class="col-12">
        <h2 class="i-margin-bottom-submodule">
          {{ $t('routes.documentalCollectionRoute.documentalCollection') }}
        </h2>
        <hr>
      </b-col>
      <b-col class="col-12">
        <ul class="navs float-right">
          <li class="i-li-active i-tab-option-active">
            <span class="text-dark">
              {{ $t('corporate.club') }}
            </span>
          </li>
        </ul>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5>
          <strong>{{ $t('clubs.corporate.clubRegistration') }}</strong>
        </h5>
        <span class="i-text-request-info">
          {{ $t('corporate.create.textRequest') }}
        </span>
      </b-col>
      <b-col cols="12">
        <b-overlay
          :show="applyOverlay"
          :class="applyOverlay ? 'p-2 mb-1' : 'mb-1'"
          opacity="1"
        >
          <template #overlay>
            <div class="d-flex align-items-center justify-content-center mt-1">
              <b-spinner
                type="border"
                variant="primary"
              />
            </div>
            <div class="d-flex justify-content-center">
              <p class="pt-1">
                {{ $t('loading') }}
              </p>
            </div>
          </template>
          <b-row>
            <!-- club image -->
            <b-col
              md="12"
              class="text-center"
            >
              <upload-image
                :path-file-avatar="clubData.clubImage"
                :tooltip-text="'generic.allowedFormat'"
                :button-text="'generic.uploadLogo'"
                :avatar-icon="'icon-0-icons-dark-image'"
                :image-size="'10rem'"
                :apply-b-link="true"
                @intance-file-x="intanceFileX"
              />
            </b-col>
            <!--/ club image -->
            <!-- form -->
            <b-col
              v-if="!applyOverlay"
              md="12"
            >
              <validation-observer ref="clubRules">
                <b-form @submit.prevent="validateClubData">
                  <b-row>
                    <!-- club info -->
                    <b-col md="6">
                      <b-form-group label-for="club-country-id">
                        {{ $t('generic.country') }}
                        <span class="text-danger">*</span>
                        <validation-provider
                          #default="{ errors }"
                          name="country"
                          rules="required"
                        >
                          <v-select
                            id="club-country-id"
                            v-model="clubData.countryId"
                            :state="errors.length > 0 ? false : null"
                            label="name"
                            :options="countries"
                            :reduce="option => option.id"
                            :placeholder="$t('generic.selectCountry')"
                          >
                            <div slot="no-options">
                              {{ $t('generic.noRecordsFound') }}
                            </div>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label-for="club-state-id">
                        {{ $t('generic.state') }}
                        <span class="text-danger">*</span>
                        <validation-provider
                          #default="{ errors }"
                          name="state"
                          rules="required"
                        >
                          <v-select
                            id="club-state-id"
                            v-model="clubData.stateId"
                            :state="errors.length > 0 ? false : null"
                            label="name"
                            :options="states"
                            :reduce="option => option.id"
                            :placeholder="$t('generic.selectState')"
                            :disabled="clubData.countryId == null"
                          >
                            <div slot="no-options">
                              {{ $t('generic.noRecordsFound') }}
                            </div>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label-for="club-name">
                        {{ $t('clubs.corporate.sportsClubName') }}
                        <span class="text-danger">*</span>
                        <validation-provider
                          #default="{ errors }"
                          name="sportsClubName"
                          rules="required|min:2|max:255|clubNameRegex"
                        >
                          <b-form-input
                            id="club-name"
                            v-model="clubData.clubName"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('clubs.corporate.placeholder.enterSportsClubName')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label-for="club-denomination">
                        {{ $t('clubs.corporate.denominationOrTradeName') }}
                        <span class="text-danger">*</span>
                        <validation-provider
                          #default="{ errors }"
                          name="denominationOrTradeName"
                          rules="required|min:2|max:255|clubNameRegex"
                        >
                          <b-form-input
                            id="club-denomination"
                            v-model="clubData.denomination"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('clubs.corporate.placeholder.enterDenomination')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!--/ club info -->
                  </b-row>
                  <b-row v-if="checkPermission(['ALLOW_ALL'])">
                    <!-- personalize club -->
                    <b-col cols="12">
                      <h5>
                        <strong>{{ $t('clubs.corporate.userInterface') }}</strong>
                      </h5>
                      <hr>
                    </b-col>
                    <!-- color pickers -->
                    <b-col sm="6">
                      <b-row>
                        <b-col
                          sm="9"
                          md="7"
                          lg="6"
                          class="mb-1"
                        >
                          <span>{{ $t('clubs.corporate.placeholder.selectColorSidebar') }}</span>
                        </b-col>
                        <b-col
                          sm="2"
                          md="2"
                          class="mb-1"
                        >
                          <verte
                            v-model="clubData.backgroundColor"
                            v-b-tooltip.hover.bottom="$t('clubs.corporate.placeholder.selectColor')"
                            menu-position="top"
                            model="hex"
                            class="float-left"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col sm="6">
                      <b-row>
                        <b-col
                          sm="7"
                          md="6"
                          lg="6"
                          class="mb-1"
                        >
                          <span>{{ $t('clubs.corporate.placeholder.selectColorButtons') }}</span>
                        </b-col>
                        <b-col
                          sm="2"
                          md="2"
                          class="mb-1"
                        >
                          <verte
                            v-model="clubData.btnColor"
                            v-b-tooltip.hover.bottom="$t('clubs.corporate.placeholder.selectColor')"
                            menu-position="top"
                            model="hex"
                            class="float-left"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                    <!-- color pickers -->
                    <b-col
                      cols="12"
                    >
                      <hr>
                      <b-button
                        id="btn-cl-cr-one"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        pill
                        class="float-right ml-1"
                        :style="colorPrimaryBtn"
                        @mouseenter="assignColorBtn(clubData.btnColor, true)"
                        @mouseout="assignColorBtn(clubData.btnColor, false)"
                      >
                        {{ ($route.name == 'edit-club') ? $t('buttons.update') : $t('buttons.finalize') }}
                      </b-button>
                      <b-button
                        class="float-right"
                        variant="secondary"
                        pill
                        @click="$router.push({ name: 'clubs' })"
                      >
                        {{ $t('buttons.cancel') }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-col>
            <b-col
              v-else
              cols="12"
              class="i-height-div"
            />
          </b-row>
        </b-overlay>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer, max, min, clubNameRegex } from '@validations'
import { BRow, BCol, BCard, BFormGroup, BForm, BFormInput, BButton, VBTooltip } from 'bootstrap-vue'
import { validatorFileSize, validatorFileType } from '@core/utils/validations/validators'
import checkPermission from '@/auth/permissions'
import countryService from '@/services/countryService'
import clubService from '@/services/clubsService'
import Ripple from 'vue-ripple-directive'
import UploadImage from '@/views/partials/UploadImage.vue'
import vSelect from 'vue-select'
import Verte from 'verte'
import 'verte/dist/verte.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BForm,
    BFormInput,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    UploadImage,
    Verte,
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  props: {
    oldActive: {
      type: Number,
      default: 0,
    },
    paramsClub: {
      type: Object,
      default: () => {},
    },
    stepNumber: {
      type: Number,
      default: null,
    },
    stepPreviousNumber: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      states: [],
      countriesList: [],
      applyOverlay: false,
      applyNewImage: false,
      previousFileHash: null,
      btnClCrOne: null, // boton personalizado
      backupImg: null,
      clubData: {
        id: null,
        clubImage: null,
        clubImageId: null,
        countryId: null,
        stateId: null,
        clubName: '',
        denomination: '',
        backgroundColor: '',
        btnColor: '',
        status: false,
      },
      filtersObj: {
        country_hash: null,
      },
      required, // validation
      integer, // validation
      min, // validation
      max, // validation
      clubNameRegex, // validation
    }
  },

  computed: {
    ...mapGetters({
      clubInfo: 'clubInfo',
      colorSidebar: 'colorRGBSidebarX',
      colorRGB: 'colorRGBX',
      colorPrimaryBtn: 'colorPrimaryBtn',
      path: 'path',
    }),

    countries() {
      const list = this.countriesList.map(c => ({ id: c.hash, name: c.labelables[0].label }))
      return list
    },
  },

  watch: {
    '$i18n.locale': function () {
      this.fetchCountries()
    },

    'clubData.countryId': function (pCountryId) {
      if (pCountryId !== null) {
        this.filtersObj.country_hash = pCountryId
        this.fetchStates()
      } else {
        this.clubData.stateId = null
      }
    },

    'clubData.backgroundColor': function (value) {
      this.assignColorSidebarX(value)
    },

    'clubData.btnColor': function (pValueX) {
      this.setColorValue(pValueX)
      this.assignColorBtn(pValueX, false)
    },

    // clubInfo(pClubInfo) {
    //   if (!this.checkPermission(['ALLOW_ALL'])) {
    //     this.clubData = {
    //       id: pClubInfo.hash,
    //       clubImage: pClubInfo.club_image_file,
    //       countryId: pClubInfo.country_hash,
    //       stateId: pClubInfo.state_hash,
    //       clubName: pClubInfo.club_name,
    //       denomination: pClubInfo.denomination,
    //       backgroundColor: pClubInfo.background_color,
    //       btnColor: pClubInfo.btn_color,
    //       status: pClubInfo.status,
    //     }
    //   }
    // },

    stepNumber(pCurrent) {
      if (pCurrent == 1) { // paso 1 wizard
        this.$refs.clubRules.validate().then(success => {
          if (success) { // paso 1 wizard listo para avanzar a paso 2
            const formClub = {
              id: this.clubData.id,
              club_image_file: this.clubData.clubImage,
              country_id: this.clubData.countryId,
              state_id: this.clubData.stateId,
              club_name: this.clubData.clubName,
              denomination: this.clubData.denomination,
              background_color: this.clubData.backgroundColor,
              btn_color: this.clubData.btnColor,
              status: this.clubData.status,
            }
            this.$emit('save-data-step-one', formClub, true, null)
          } else {
            this.$emit('save-data-step-one', null, false, null)
          }
        })
      }
    },

    stepPreviousNumber(sCurrent) {
      if (sCurrent == 0) {
        this.$emit('save-data-prev-one', null)
      }
    },
  },

  mounted() {
    this.clubData.backgroundColor = this.colorSidebar
    this.clubData.btnColor = this.colorRGB
    this.btnClCrOne = document.querySelector('.btn-primary')

    this.assignColorBtn(this.colorRGB, false)
    this.fetchCountries()

    setTimeout(() => { this.assignClubInfo() }, 200)
  },

  destroyed() {
    if (this.checkPermission(['ALLOW_ALL'])) {
      this.assignColorSidebarX(this.colorSidebar)
      this.setColorValue('#6abeff')
      this.assignColorBtn(this.colorRGB)
    }
  },

  methods: {
    ...mapActions({
      setColorValue: 'setColorValue',
      mediaFileUpload: 'mediaFile/upload',
      mediaFileDestroy: 'mediaFile/destroy',
    }),

    validatorFileSize,
    validatorFileType,
    checkPermission,

    fetchCountries() {
      countryService.getCountries().then(({ data }) => {
        this.countriesList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    fetchStates() {
      countryService.getStates(this.filtersObj).then(({ data }) => {
        this.states = data.data.map(c => ({ id: c.hash, name: c.name }))
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    assignClubInfo() {
      if (this.$route.name == 'edit-club') {
        this.fetchClubInfo(this.$route.params.id)
      } else if (!this.checkPermission(['ALLOW_ALL'])) {
        if (this.oldActive == 2) {
          this.clubData = {
            id: this.paramsClub.id,
            clubImage: this.paramsClub.club_image_file,
            countryId: this.paramsClub.country_id,
            stateId: this.paramsClub.state_id,
            clubName: this.paramsClub.club_name,
            denomination: this.paramsClub.denomination,
            backgroundColor: this.paramsClub.background_color,
            btnColor: this.paramsClub.btn_color,
            status: this.paramsClub.status,
          }
        } else this.fetchClubInfo(this.clubInfo.hash)
      }
    },

    fetchClubInfo(clubId) {
      this.applyOverlay = true

      clubService.getClubInfo(clubId).then(({ data }) => {
        const params = data.data
        this.clubData = {
          id: params.hash,
          clubImage: params.club_image_media_file == null ? null : this.path + params.club_image_media_file.url_relative,
          clubImageId: params.club_image_media_file == null ? null : params.club_image_media_file.hash,
          countryId: params.country_hash,
          stateId: params.state_hash,
          clubName: params.club_name,
          denomination: params.denomination,
          backgroundColor: params.background_color,
          btnColor: params.btn_color,
          status: params.status,
        }

        this.backupImg = JSON.parse(JSON.stringify(this.clubData.clubImage))
        this.applyOverlay = false
      }).catch(error => {
        this.applyOverlay = false
        this.responseCatch(error)
      })
    },

    intanceFileX(pImage) {
      // const validSize = this.validatorFileSize(pImage)
      const validType = this.validatorFileType(pImage)

      // if (!validSize) {
      //   this.clubData.clubImage = null
      //   this.showError(`${this.$t('the-file-size-exceeds-the-allowed-size')}: 2MB`)
      //   setTimeout(() => { this.clubData.clubImage = this.backupImg }, 100)
      // } else

      if (!validType) {
        this.clubData.clubImage = null
        this.showError(`${this.$t('only-documents-in-PDF-and-image-format-are-allowed')}`)
        setTimeout(() => { this.clubData.clubImage = this.backupImg }, 100)
      } else {
        this.previousFileHash = JSON.parse(JSON.stringify(this.clubData.clubImageId))
        this.clubData.clubImage = pImage

        if (this.$route.name == 'edit-club') {
          this.saveFileUpload(pImage)
        }
      }
    },

    async saveFileUpload(fileData) {
      const formData = new FormData()

      formData.append('file', fileData)
      formData.append('folder', 'clubs')
      formData.append('club_id', this.clubData.id)

      await this.mediaFileUpload(formData).then(response => {
        const { data } = response.data
        this.applyNewImage = true
        this.clubData.clubImage = this.path + data.url_relative
        this.clubData.clubImageId = data.hash
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    validateClubData() {
      this.$refs.clubRules.validate().then(success => {
        if (success) {
          if (this.$route.name == 'edit-club') {
            const dataFormCl = {
              id: this.clubData.id,
              club_image_file: this.clubData.clubImage == null ? null : this.clubData.clubImage.slice(this.path.length),
              club_image_file_id: this.clubData.clubImageId,
              country_id: this.clubData.countryId,
              state_id: this.clubData.stateId,
              club_name: this.clubData.clubName,
              denomination: this.clubData.denomination,
              background_color: this.clubData.backgroundColor,
              btn_color: this.clubData.btnColor,
              status: this.clubData.status,
            }

            clubService.updateClub(dataFormCl, this.clubData.id).then(({ data }) => {
              if (this.applyNewImage) {
                this.destroyImage()
              }
              this.setColorValue('#6abeff')
              this.responseSuccessUpdate(data.message)
              setTimeout(() => { this.$router.replace({ name: 'clubs' }) }, 500)
            }).catch(error => {
              this.responseCatch(error)
            })
          } else {
            const dataFormCl = new FormData()

            dataFormCl.append('club_image_file', this.clubData.clubImage)
            dataFormCl.append('country_id', this.clubData.countryId)
            dataFormCl.append('state_id', this.clubData.stateId)
            dataFormCl.append('club_name', this.clubData.clubName)
            dataFormCl.append('denomination', this.clubData.denomination)
            dataFormCl.append('background_color', this.clubData.backgroundColor)
            dataFormCl.append('btn_color', this.clubData.btnColor)
            dataFormCl.append('status', 1)

            clubService.storeClub(dataFormCl).then(({ data }) => {
              this.setColorValue('#6abeff')
              this.responseSuccessCreate(data.message)

              setTimeout(() => {
                this.$router.replace({ name: 'clubs' })
              }, 500)
            }).catch(error => {
              this.responseCatch(error)
            })
          }
        }
      })
    },

    destroyImage() {
      if (this.previousFileHash != null) {
        this.mediaFileDestroy(this.previousFileHash).then(() => {
          console.log('ok')
        }).catch(error => {
          this.responseCatch(error)
        })
      }
    },

    assignColorBtn(pColor, pFlagS) {
      if (this.checkPermission(['ALLOW_ALL'])) {
        const shadowX = pFlagS ? `${pColor} 0px 8px 25px -8px !important;` : '0 0 0 0'

        this.btnClCrOne.setAttribute('style', `
          background-color: ${pColor} !important;
          border-color: ${pColor} !important;
          box-shadow: ${shadowX};
       `)
      }
    },

    assignColorSidebarX(pColorSr) {
      const sOneX = document.querySelector('#section-one')
      const sTwoX = document.querySelector('#section-two')
      const sThreeX = document.querySelector('#section-three')

      sOneX.setAttribute('style', `
        background-color: ${pColorSr} !important;
      `)

      sTwoX.setAttribute('style', `
        background-color: ${pColorSr} !important;
      `)

      sThreeX.setAttribute('style', `
        background-color: ${pColorSr} !important;
      `)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
